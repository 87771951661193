<template>
  <div class="dots-menu">
    <v-menu nudge-bottom="30" bottom left :attach="true">
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark icon v-bind="attrs" v-on="on" @click="setItem">
          <v-icon :color="color">mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item class="menu-list-item" @click="openDialog">
          <v-list-item-title class="menu-title">Löschen</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { BASE_BLACK_COLOR } from '../../../../../src/utils/constants';

export default {
  name: 'DotsMenu',
  props: {
    setItem: Function,
    openDialog: Function,
  },
  data() {
    return {
      color: BASE_BLACK_COLOR,
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.dots-menu {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}
</style>
