<template>
  <v-container v-if="!error">
    <ModalConfirm
      :textActionButtons="textActionButtons"
      @change="onDialogChanged"
      :dialog="dialog"
      :confirm-action="deleteAndUpdateState"
      :text="`Sind sie sicher, dass sie '${currentObj.title}' löschen wollen?`"
      title="Vorsicht!"
    />
    <div v-if="allReports.length" class="all-reports">
      <v-data-iterator
        :items="allReports"
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        no-data-text="Erstellen Sie Ihre erste Auswertung"
        hide-default-footer
        class="group-iterator"
      >
        <template v-slot:default="props">
          <v-row>
            <CreateNewReport
              :action="action"
              class="dashboard__element new-report"
            />
            <v-col
              v-for="(item, index) in props.items"
              :key="index"
              cols="12"
              sm="12"
              md="6"
              lg="4"
              xl="3"
              class="dashboard__element"
            >
              <v-col v-if="!validate(item)">
                <v-card>
                  <v-icon color="red" class="alert" v-if="item.error">
                    {{ 'mdi-alert' }}
                  </v-icon>
                  <v-skeleton-loader type="card"> </v-skeleton-loader>
                </v-card>
              </v-col>
              <v-col v-else>
                <v-card>
                  <DotsMenu
                    :setItem="() => setItem(item)"
                    :openDialog="openDialog"
                  />
                  <v-sparkline
                    fill
                    :gradient="['#1194db', '#5ac189']"
                    :line-width="2"
                    :padding="0"
                    :smooth="2"
                    :value="(item.data || []).map((i) => i.mittelwert || 0)"
                    auto-draw
                  >
                  </v-sparkline>
                  <v-card-text class="text-card base-normal-color">
                    <div class="text-h6">
                      {{ item.title }}
                    </div>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions
                    class="justify-end"
                    @click="() => goSingleReport(item.slug)"
                  >
                    <v-btn text :color="color">Öffnen</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-col>
          </v-row>
        </template>
        <template v-slot:footer>
          <v-row class="mt-2" align="center" justify="center">
            <span class="mr-4 base-normal-colo">
              Seite {{ page }} von {{ numberOfPages }}</span
            >
            <v-btn
              :color="color"
              fab
              small
              dark
              class="mr-1"
              @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn :color="color" fab small dark class="ml-1" @click="nextPage">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </div>
    <v-row v-if="!allReports.length" class="get-started">
      <CreateNewReport :action="action" class="dashboard__element" />
    </v-row>
  </v-container>
</template>

<script>
import CreateNewReport from '../../../../../src/components/fmh_components/common/CreateNewReport/CreateNewReport';
import DotsMenu from '../../common/DotsMenu/DotsMenu';
import ModalConfirm from '../../common/ModalConfirm/ModalConfirm';

import { mapActions } from 'vuex';
import { BASE_BLACK_COLOR } from '../../../../../src/utils/constants';

export default {
  name: 'Reports',
  components: { DotsMenu, CreateNewReport, ModalConfirm },
  props: {
    action: {
      type: Function,
    },
    allReports: {
      type: Array,
    },
    error: {
      type: Boolean,
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.allReports.length / this.itemsPerPage);
    },
  },
  data() {
    return {
      textActionButtons: {
        confirm: 'Ja',
        reject: 'Nein',
      },
      currentObj: {},
      color: BASE_BLACK_COLOR,
      page: 1,
      itemsPerPage: 10,
      dialog: false,
    };
  },
  methods: {
    ...mapActions({
      deleteReport: 'wettbewerb/deleteReport',
    }),
    onDialogChanged(val) {
      this.dialog = val;
    },
    openDialog() {
      this.dialog = true;
    },
    setItem(item) {
      this.currentObj = item;
    },
    async deleteAndUpdateState() {
      await this.deleteReport({
        slug: this.currentObj.slug,
        reportType: this.currentObj.report_type,
      });
      this.dialog = false;
    },
    validate(item) {
      return item?.data && item?.data.length > 0;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    goSingleReport(id) {
      this.$router.push(
        `${this.$router.currentRoute.path}/wettbewerb-report/${id}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard__element {
  padding-right: 50px;
  padding-bottom: 60px;
  [class~='v-card'] {
    height: 100%;
  }
}
.text-card {
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}
.get-started {
  padding-top: 10px;
  [class~='create-new-report'] {
    height: 100% !important;
  }
}
</style>
